"use client";

import { useAnalytics } from "@everfund/event-detective";
import { load, trackPageview as trackPageView } from "fathom-client";
import { useSearchParams } from "next/navigation";
import { ReactElement, useEffect } from "react";

import { env } from "~/env";
import { usePathname } from "~/navigation";

export const LoadFathom = (): ReactElement => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { state } = useAnalytics();

  // Load the Fathom script on mount
  useEffect(() => {
    if (
      state?.consent?.gdprPurposes?.measurement &&
      env.NEXT_PUBLIC_FATHOM_SITE_ID
    ) {
      load(env.NEXT_PUBLIC_FATHOM_SITE_ID);
    }
  }, [state?.consent?.gdprPurposes?.measurement]);

  // Record a page-view when route changes
  useEffect(() => {
    if (!pathname) {
      return;
    }
    trackPageView({
      referrer: document.referrer,
      url: pathname + searchParams.toString(),
    });
  }, [pathname, searchParams]);

  return null;
};
